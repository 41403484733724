import React, {useState, useRef, useEffect} from 'react';
import razrabotkaImg from '../../../../assets/images/projects/newProjects/torex-mobile.png';
import './scss/RazrabotkaSection.scss';
import BlackButton from '../../../Common/BlackButton/BlackButton';
import PartnersSection from './PartnersSection';
import { NavLink } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import FactsSlider from '../../../FactsSlider/FactsSlider';
import bounceMobile from '../../../../assets/images/3d-asap.webm';

const RazrabotkaSection = () => {

    return (
        <section className="worked-section" data-aos="fade-up">
            <div className="wrapper worked-section__wrapper">
                <div className="worked-section-left">
                    <div className="worked-section-box">
                        <h1 className='h1 worked-section-left__title'>Разработка цифровых продуктов</h1>
                        <p className="body-xl worked-section-left__subtitle">
                            Мы знаем, как зарабатывать с помощью IT-продуктов и можем говорить на языке бизнеса.
                        </p>
                        
                        <FactsSlider/>
                        
                    </div>
                    <PartnersSection />
                </div>
                <div className="worked-section-right">
                    <video loop muted autoplay="autoplay" width="100%" height="100%" className='header__bg'>
                        <source src={bounceMobile} type="video/webm" />
                    </video>
                    <div className="worked-section-right__box">
                        <p className="body-xl worked-section-right__title">
                            Запускаем MVP продукта и развиваем его на основе метрик.
                        </p>
                        <NavLink
                            to={`/brief`}
                            onClick={() => {
                            ReactGA.event({
                                category: 'appayer_sur_le_bouton',
                                action: 'aller_sur_une_autre_page',
                                label: 'aller_sur_une_autre_page_toit_de_site'
                            });
                            ym('reachGoal', 'startproject');
                            }}
                            className=""
                        >
                            <BlackButton>
                                Запустим продукт вместе
                            </BlackButton>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RazrabotkaSection