import React from 'react';
import './scss/VakancySection.scss';
import vakanceImg from '../../../../assets/images/services/vakancy.png';

const VakancySection = () => {
  return (
    <section className="vakancy-section" data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
        <img src={vakanceImg} alt="" />
        <div className="wrapper vakancy-section__wrapper">
            <p className="h3 vakancy-section__title">Развиваем сильную команду</p>
            <div className="vakancy-box">
                <div className="vakancy-item"></div>
                <div className="vakancy-item">
                    <p className="body-xl">
                        Мечтаешь создавать крутые IT-продукты, которые будут делать жизнь людей лучше? Ждем твое резюме 
                    </p>
                    <a href="mailto:hr@asap-ag.ru" target='_blank' className="h3">hr@asap-ag.ru</a>
                    <p className="label-text">Средний период работы специалиста в компании - более 2-х лет</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default VakancySection