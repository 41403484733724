import React from 'react';
import { NavLink } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import './scss/AsapLive.scss';
import personImg from '../../../../assets/images/icons/daniil.png';
import vkIcon from '../../../../assets/images/icons/vk.png';
import telegaIcon from '../../../../assets/images/icons/telega.png'
import vcIcon from '../../../../assets/images/icons/vc.png'
import BlackButton from '../../../Common/BlackButton/BlackButton';

const AsapLive = () => {
  return (
    <section className="asap-live" data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
        <div className="wrapper asap-live__wrapper">
            <div className="asap-live-item">
                <div className="asap-live-item-header">
                    Asap live
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="9" fill="#38C793"/>
                    </svg>
                </div>
                <div className="asap-line-footer">
                    <a href="https://t.me/vasilievit" target='_blank' className="asap-line-footer-item">
                        <img src={personImg} alt="Даниил Васильев" />
                        <div className="asap-line-footer-item__textbox">
                            <p className="label-text">Telegram-канал CEO ASAP</p>
                            <span>t.me/vasilev</span>
                        </div>
                    </a>
                    <div className="asap-line-footer-box">
                        <a href="https://vk.com/asap_ru" target='_blank' className="asap-line-footer-item">
                            <img src={vkIcon} alt="Группа в VK" />
                            <div className="asap-line-footer-item__textbox">
                                <p className="label-text">Группа в VK</p>
                                <span>vk.me/asap</span>
                            </div>
                        </a>
                        <a href="https://t.me/asap_agency" target='_blank' className="asap-line-footer-item">
                            <img src={telegaIcon} alt="Telegram-канал" />
                            <div className="asap-line-footer-item__textbox">
                                <p className="label-text">Telegram-канал</p>
                                <span>t.me/asap_agency</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="asap-live-item">Статьи</div>
            <div className="asap-live-item">
                <div className="vc-list">
                    <a href="https://vc.ru/marketing/895393-kak-my-uvelichivaem-sredniy-chek-v-internet-magazine" target='_blank' className="line-vc-item">
                        <div className="line-vc-item__header">
                            <div className="line-vc-item__icon">
                                <img src={vcIcon} alt="vc.ru" />
                            </div>
                            <p className='label-text'>29 октября 2023</p>
                        </div>
                        <p className="body-xl">Как мы увеличиваем средний чек в интернет-магазине</p>
                    </a>
                    <a href="https://vc.ru/hr/457629-pro-sochetanie-empatii-i-avtoriteta-vizualnyy-opyt-i-kogda-chelovek-nemnogo-podgoraet" target='_blank' className="line-vc-item">
                        <div className="line-vc-item__header">
                            <div className="line-vc-item__icon">
                                <img src={vcIcon} alt="vc.ru" />
                            </div>
                            <p className='label-text'>11 июля 2022</p>
                        </div>
                        <p className="body-xl">Про сочетание эмпатии и авторитета, визуальный опыт и когда человек немного «подгорает»</p>
                    </a>
                    <a href="https://vc.ru/u/582595-asap/366484-keys-o-razrabotke-internet-magazina-dlya-krupneyshey-seti-stroitelnyh-magazinov-saratovskoy-oblasti-stroy-s" target='_blank' className="line-vc-item">
                        <div className="line-vc-item__header">
                            <div className="line-vc-item__icon">
                                <img src={vcIcon} alt="vc.ru" />
                            </div>
                            <p className='label-text'>11 июля 2022</p>
                        </div>
                        <p className="body-xl">
                            Кейс о разработке интернет-магазина для крупнейшей сети строительных магазинов Саратовской области — Строй-С
                        </p>
                    </a>
                </div>
                <NavLink
                    to={`/papers`}
                    className=""
                >
                    <BlackButton>Все статьи</BlackButton>
                </NavLink>
            </div>
        </div>
    </section>
  )
}

export default AsapLive