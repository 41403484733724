import React from 'react';
import './scss/SocialSection.scss';
import personImg from '../../../../assets/images/icons/daniil.png';
import { useDeviceType } from '../../../../hooks';

const SocialSection = ({ titleText, personText, children, ...props }) => {
    const {mobile} = useDeviceType();

    return (
        <section className="social-section" data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
            {mobile
                ?<>
                    <div className="wrapper social-section__wrapper">
                        <div className="social-section-item">
                            <p className="social-section-tag label-text">{titleText}</p>
                        </div>
                        <div className="social-section-item">
                            <p className="body-xl">
                                {personText}
                            </p>
                            <div className="personbox">
                                <img src={personImg} alt="Даниил Васильев" />
                                <div className="personbox-text">
                                    <span>Даниил Васильев</span>
                                    <p className="label-text">CEO & Founder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {children}
                </>
                :<div className="wrapper social-section__wrapper">
                    <div className="social-section-item"></div>
                    <div className="social-section-item">
                        <p className="social-section-tag label-text">{titleText}</p>
                    </div>
                    <div className="social-section-item">
                        <p className="body-xl">
                            {personText}
                        </p>
                        <div className="personbox">
                            <img src={personImg} alt="Даниил Васильев" />
                            <div className="personbox-text">
                                <span>Даниил Васильев</span>
                                <p className="label-text">CEO & Founder</p>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
                }
            
        </section>
    )
}

export default SocialSection