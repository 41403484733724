import React, { useEffect, useRef, useState } from 'react';
import './assets/sass/main.scss';
import MainPage from './components/Pages/MainPage/MainPage';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ProjectPage from './components/Pages/ProjectPage/ProjectPage';
import AgencyPage from './components/Pages/AgencyPage/AgencyPage';
import AOS from 'aos';
import WithDeviceType from './components/HOCs/WithDeviceType';
import Menu from './components/Common/Menu';
import ProjectsPage from './components/Pages/ProjectsPage/ProjectsPage';
import ServicesPage from './components/Pages/ServicesPage/ServicesPage';
import ArticlesPage from './components/Pages/ArticlesPage/ArticlesPage';
import ArticlePage from './components/Pages/ArticlePage/ArticlePage';
import ContactsPage from './components/Pages/ContactsPage/ContactsPage';

import PromotionStorePage from './components/Pages/PromotionStorePage';

import AppSuccessPage from './components/Pages/AppSuccessPage';

import BriefPage from './components/Pages/BriefPage/BriefPage';
import PageNotFound from './components/Pages/404Page/404';
import { AppProvider } from './AppContext';
import { AnimatePresence } from 'framer-motion';
import { YMInitializer } from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';
import GA from 'react-ga';
import { scrollInit } from './assets/vendors/js/scroll';
import ConsultationPage from './components/Pages/ConsultationPage/ConsultationPage';
import PapersPage from './components/Pages/PapersPage/PapersPage';
import './assets/sass/kit.scss';
import OtraslExpertiza from './components/Pages/OtraslExpertiza/OtraslExpertiza';
import EcommercePage from './components/Pages/EcommercePage/EcommercePage';
import ProizvodstvoPage from './components/Pages/ProizvodstvoPage/ProizvodstvoPage';
import NedwizPage from './components/Pages/NedwizPage/NedwizPage';
const App = ({ location }) => {
  let [isIndexPage, setIsIndexPage] = useState(false);
  let mainRef = useRef(null);

  GA.initialize('UA-196738450-1');
  GA.pageview(window.location.pathname + window.location.search);
  ReactPixel.init('450484779497030');
  ReactPixel.pageView();

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 100, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 700, // values from 0 to 3000, with step 50ms
      easing: 'ease-out', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false // whether elements should animate out while scrolling past them
      // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });

    scrollInit();
  }, []);

  return (
    <main className="main" ref={mainRef}>
      <YMInitializer
        accounts={[48220988, 85053448]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
        version="2"
      />
      <AppProvider mainRef={mainRef}>
        <Menu isIndexPage={isIndexPage} />
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
        
            <Route exact path="/projects/vkusno-i-bistro">
              <Redirect to="/projects/vkusno-i-bystro" />
            </Route>
            <Route
              exact
              path="/"
              render={() => <MainPage isIndexPage={isIndexPage} setIsIndexPage={setIsIndexPage} />}
            />
            <Route
              exact
              path="/industries"
              render={() => <OtraslExpertiza isIndexPage={isIndexPage}/>}
            />
             <Route
              exact
              path="/ecommerce"
              render={() => <EcommercePage isIndexPage={isIndexPage}/>}
            />
            <Route
              exact
              path="/manufacturing"
              render={() => <ProizvodstvoPage isIndexPage={isIndexPage}/>}
            />
            <Route
              exact
              path="/realty"
              render={() => <NedwizPage isIndexPage={isIndexPage}/>}
            />
            <Route
              exact
              path="/projects"
              render={() => {
                return <ProjectsPage isIndexPage={isIndexPage} />;
              }}
            />
            <Route path="/projects/:projectName" render={() => <ProjectPage />} />
            <Route exact path="/agency" render={() => <AgencyPage isIndexPage={isIndexPage} />} />
            <Route
              exact
              path="/services/:serviceName?"
              render={() => <ServicesPage isIndexPage={isIndexPage} />}
            />
            {/*<Route path="/services/:servicesName" render={() => <ServicesItemPage/>}/>*/}
            <Route
              exact
              path="/articles"
              render={() => <ArticlesPage isIndexPage={isIndexPage} />}
            />
            <Route exact path="/article" render={() => <ArticlePage isIndexPage={isIndexPage} />} />
            <Route
              exact
              path="/contacts"
              render={() => <ContactsPage isIndexPage={isIndexPage} />}
            />
            <Route
              exact
              path="/papers"
              render={() => <PapersPage isIndexPage={isIndexPage} />}
            />
            <Route exact path="/brief" render={() => <BriefPage />} />
            <Route
              exact
              path="/razrabotkaim"
              render={() => (
                <PromotionStorePage isIndexPage={isIndexPage} setIsIndexPage={setIsIndexPage} />
              )}
            />
            <Route
              exact
              path="/success"
              render={() => (
                <AppSuccessPage isIndexPage={isIndexPage} setIsIndexPage={setIsIndexPage} />
              )}
            />
            <Route
              exact
              path="/consultation"
              render={() => <ConsultationPage isIndexPage={isIndexPage} />}
            />
            <Route path="*" render={PageNotFound} />
          </Switch>
        </AnimatePresence>
      </AppProvider>
    </main>
  );
};

export default WithDeviceType(withRouter(App));
