import React from 'react';
import './scss/HistorySection.scss';
import { NavLink } from 'react-router-dom';

const HistorySection = () => {
  return (
    <section className="history-section" data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
            <NavLink
                to={`/ecommerce`}
                className="history-item"
            >
                <span className="history-item__title">E-commerce & Retail</span>
                <span className="history-item__date label-text">C 2017 года</span>
                <div className="history-textbox">
                    <p className="history-item__text body-xl">
                        Интернет-магазины, мобильные приложения, онлайн-конфигураторы, web-сервисы, корпоративные сайты.    
                    </p>
                    <p className="history-item__text body-xl">
                        Повышаем выручку интернет-магазинов и оффлайн-продажи.
                    </p>
                    <p className="history-item__text body-xl">    
                        Увеличиваем конверсию и средний чек.
                    </p>
                </div>
            </NavLink>
            <NavLink
                to={`/manufacturing`}
                className="history-item"
            >
                <span className="history-item__title">PromTech</span>
                <span className="history-item__date label-text">С 2019 года</span>
                <div className="history-textbox">
                    <p className="history-item__text body-xl">
                        Корпоративное ПО, интеграции с оборудованием и внешними сервисами, сайты промышленных компаний.
                    </p>
                    <p className="history-item__text body-xl">
                        Автоматизация производственных процессов за счёт IT-инструментов.
                    </p>
                </div>
            </NavLink>
            <NavLink 
                to={`/realty`}
                className="history-item"
            >
                <span className="history-item__title">PropTech</span>
                <span className="history-item__date label-text">С 2020 года</span>
                <p className="history-item__text body-xl">
                    Корпоративное ПО, личные кабинеты, мобильные приложения, интеграции с внешними сервисами.
                </p>
            </NavLink>
    </section>
  )
}

export default HistorySection