import React, { useEffect, useState } from 'react';
import './scss/ExpertizaSection.scss';
import { projectsAPI } from '../../../../api';
import { useDeviceType } from '../../../../hooks';
import { NavLink, useHistory } from 'react-router-dom';
import BlackButton from '../../../Common/BlackButton/BlackButton';

const ExpertizaSection = ({titleText, children}) => {
    // const [projects, setProjects] = useState([])
    
    // useEffect(() => {
    //     projectsAPI.getMainProjects().then(res => {
    //         setProjects(res.items)
    //     })
    // }, [])

    const { mobile } = useDeviceType();
    const history = useHistory();

    const [isProjectsPage] = useState(history.location.pathname === '/projects');

    const getImgSrc = (index, item) => {
        if (isProjectsPage) {
        return index !== 0 && (index + 1) % 3 === 0 ? item.mainPic : item.pic;
        }
        return item.pic;
    };
    return (
        <div className="expertiza" data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
            <div className="wrapper expertiza__wrapper">
                <h3 className="h3 expertiza__title">
                    {titleText}
                </h3>
                {children}
                {mobile &&
                <NavLink to={'/projects'}>
                    <BlackButton>Все проекты</BlackButton>
                </NavLink>
                }
            </div>
        </div>
    )
}

export default ExpertizaSection